<template>
  <div class="container" :class="page_options.source == 'insurance' ? 'not-padding' : ''">
    <div class="block-module" v-if="page_options.source == 'insurance'">
      <!-- <div class="block-module"> -->
      <div class="tab-header">
        <tabs-tmpe />
      </div>
    </div>
    <div class="place">
      <div class="box info">
        <div class="rest-box">
          <div class="status">
            <i class="iconfont icon-chenggong" v-if="page_options.status == 1"></i>
            <i class="iconfont icon-shibai" v-else></i>
          </div>
        </div>
        <div class="main-box">
          <h3 class="info-title">{{page_options.status_msg}}</h3>
          <p class="info-descriptions" v-html="page_options.descriptions"></p>
          <template v-for="(item,index) in page_options.text_list">
            <p class="info-descriptions" :key="index" v-html="item"></p>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import tabsTmpe from '@/views/insurance/components/tabs-temp/tabs-temp.vue';
  export default {
    // 允许组件模板递归地调用自身
    name: 'Succeed',
    // 声明一组可用于组件实例中的组件
    components: {
      tabsTmpe,
    },
    // 一个用于从父组件接收数据的数组或对象
    props: {
      textList: {
        type: Array,
        default: () => [],
      }
    },
    // 该函数返回组件实例的 data 对象
    data() {
      return {
        page_options: { // 页面信息参数
          order_id: '',
          status: '',
          status_msg: '标题',
          descriptions: '系统将在1-3个工作日内为您审核，请您耐心等待！',
          text_list: ['<a style="color: red;">文本</a>'], // 文本
        },
      }
    },
    // 计算属性：
    computed: {

    },
    // 钩子函数--侦听data变动：
    watch: {

    },
    // 在实例创建完成后被立即同步调用
    methods: {
      /**
       * 获取页面数据
       */
      getPageDataFun() {
        // 获取路由信息
        this.getRouterInfoFun().then(res => {
          console.log('获取路由信息 res == ', res)
          let {
            query
          } = res;
          if (query.data) {
            let resData = JSON.parse(decodeURIComponent(query.data));
            console.log('resData == ', resData)
            this.page_options = Object.assign(this.page_options, resData);
            console.log('this.page_options == ', this.page_options)
          } else {
            // 404
          }
        })
      },
      /**
       * 跳转首页
       */
      goToHomePage() {
        this.myRouterPush({
          path: '/insurance?id=1',
        })
      },
      /**
       * 获取路由信息
       */
      getRouterInfoFun() {
        return this.myGetRouter()
        // .then(res => {
        //   console.log('获取路由信息 res == ',res)
        //   // let { order_id } = res.query;
        //   this.orderData = res.query
        //   console.log('获取路由信息 res == ',res)
        //   resolve(res.query);
        // })
      },
      /**
       * 查看订单
       */
      lookingOrderFun() {
        let {
          order_id
        } = this.page_options;
        this.myRouterPush({
          path: `/insurance/query/index?id=${order_id}`,
        })
      },
    },
    // 生命周期动作侦听：创建后(此时可用this.xx获取当前实例数据)
    created() {

    },
    // 生命周期动作侦听：挂载后(此时可获取dom元素数据)
    mounted() {
      // 获取页面数据
      this.getPageDataFun();
    },
    beforeCreate() { // 创建前

    },
    beforeMount() { // 挂载前

    },
    beforeUpdate() { // 更新前

    },
    updated() { // 更新后

    },
    beforeDestroy() { // 销毁前

    },
    destroyed() { // 销毁后

    },
    activated() { // 缓存保持，需要由 keep-alive 触发

    },
  }

</script>

<!--
  1、当 style 标签具有该 scoped 属性时，其 CSS 将仅应用于当前组件的元素。
  2、处于 scoped 样式中的选择器如果想要做更“深度”的选择，也即：影响到子组件，可以使用 :deep() 这个伪类。
  tips: :deep() 伪类，只能向子级渗透，即只影响子组件；
-->
<style lang="scss" scoped>
  .container {
    box-sizing: border-box;
    min-height: 100vh;
    background: #f5f5f5;
    padding-top: 30px;

    &.not-padding {
      padding-top: 0;
    }

    .place {
      box-sizing: border-box;
      padding: 0 $theme-widht-whiteedge;
      // width: $theme-view-widht;
      max-width: $theme-view-widht;
      min-width: $theme-view-min-widht;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 40px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0 0 14px -21px rgba(0, 0, 0, .6);

      .box {
        display: flex;

        .rest-box {
          width: 42px;
          margin-right: 20px;

          .status {
            height: 42px;

            .iconfont {
              font-size: 42px;
              color: #E62129;
            }
          }
        }

        .main-box {
          flex: 1;
          min-width: 0;
        }

        &.info {
          .info-title {
            margin-top: 6px;
            font-weight: 700;
            font-size: 22px;
            color: #444343;
          }

          .info-descriptions {
            margin-top: 5px;
            font-size: 14px;
            color: #444343;

            &:first-of-type {
              margin-top: 16px;
            }
          }

          .main-box {}
        }
      }
    }
  }


  .block-module {
    background: #fff;
    border-bottom: 24px solid #F5F5F5;

    &.not-border-bottom {
      border-bottom: none;
    }

    .tab-header {
      box-sizing: border-box;
      padding: 10px $theme-widht-whiteedge;
      // width: $theme-view-widht;
      max-width: $theme-view-widht;
      min-width: $theme-view-min-widht;
      margin: 0 auto;
      display: flex;
      justify-content: flex-end;
    }
  }

</style>
